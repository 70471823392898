// Special Task Force District Api
const specialTaskForceDistrict = '/task-force/task-force-activity/'
export const specialTaskForceDistrictList = specialTaskForceDistrict + 'list'
export const specialTaskForceDistrictStore = specialTaskForceDistrict + 'store'
export const specialTaskForceDistrictUpdate = specialTaskForceDistrict + 'update'
export const specialTaskForceDistrictApprove = specialTaskForceDistrict + 'approve'
export const specialTaskForceDistrictToggle = specialTaskForceDistrict + 'toggle-status'

export const specialTaskForceDistrictReportList = '/task-force/task-force-activity/report'
export const taskForceRecommendationReport = '/task-force/task-force-activity/recommendation-report'
export const taskForceSummaryReport = '/task-force/task-force-activity/summary-report'
